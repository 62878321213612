import React, {useState, useEffect} from 'react'
import Select from '../form/Select'
import toast from '../Toast'
import withConfig from '../../wrappers/withConfig'


const WidgetTypes = ({
    config,
    control,
    initialValue,
    disabled,
    value,
    onChange,
    helper,
    errors

}) => {

    const { API_URL } = config
    const [widgetTypes, setWidgetTypes] = useState([])
    const widgetOptions = widgetTypes.map((x) => ({ label: x, value: x }))
    
    useEffect(() => {
        fetch(`${API_URL}/widget/getWidgetTypes`)
            .then(async (response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then((response) => {
                setWidgetTypes(response)
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Widget Types:' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server'),
                })
            })
    }, [API_URL])
    
    return(
        <Select
        label="Widget Type"
        name="WidgetType"
        value={value}
        control={control}
        options={widgetOptions}
        disabled={disabled}
        initialValue={initialValue}
        rules={{
            required: {
                value: true,
                message: 'This field is required.',
            },
        }}
        onChange={onChange}
        errors={errors}
        helper={helper}
    />
)
        }

export default withConfig(WidgetTypes)
