import React from 'react'

const defaultAPIURL = '/api/api'

const withConfig = WrappedComponent => {
    return class extends React.Component {
        state = {
                config: {
                    "API_URL": defaultAPIURL,
                    ...window.$config,
                }
            }
        
        render() {
            return (
                <WrappedComponent config={this.state.config} {...this.props} />
            )
        }
    }
}

export default withConfig
