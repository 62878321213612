import React, { createContext, useState, useEffect, useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import getLayouts from '../../utils/widgets/getLayouts'
import toast from '../elem/Toast'
import withConfig from './withConfig'

const CurrentDashboardContext = createContext(null)

const CurrentDashboardContextProvider = ({ config, children }) => {
    const [currentDashboard, setCurrentDashboard] = useState(null)
    const [pageLoading, setPageLoading] = useState(true)
    const [widgetGroups, setWidgetGroups] = useState([])
    const [currentWidgetGroup, setCurrentWidgetGroup] = useState({})
    const [editingLayout, setLayoutEditingState] = useState(false)
    const [layoutState, setLayoutState] = useState({})
    const location = useLocation()
    const {
        dashboardName,
        pageName: currentPage,
        detailKey,
        pageId,
        widgetId
    } = useParams()
    const [expandedWidgetId, setExpandedWidgetId] = useState(null)
    const [sharedPageKey, setSharedPageKey] = useState(null)
    const [sharedPageId, setSharedPageId] = useState(null)
    const [newObject, setNewObject] = useState(null)
    const { API_URL } = config // api url configured in public/config.<development|production>.js

    const defaultDateLabel = useMemo(() => currentWidgetGroup && currentWidgetGroup.DefaultDateRange ? currentWidgetGroup.DefaultDateRange : 'Last Year', [currentWidgetGroup])

    useEffect(() => {
        setPageLoading(true)
        fetch(`${API_URL}/dashboard/${dashboardName}`)
            .then(async (response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then((response) => {
                const data = response.data
                setCurrentDashboard(data)
                const { Pages: widgetGroups } = data
                setWidgetGroups(widgetGroups)
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                    'Widget Groups: ' +
                    (e.message
                        ? e.message
                        : 'Unable to connect to the server. Please try again later.'),
                    })
                })
            .finally(() => setPageLoading(false))
        }, [API_URL, dashboardName, newObject])



    useEffect(() => {
        if (widgetGroups.length) {
            if (pageId) {
                const matchingWidgetGroup = widgetGroups.find(widgetGroup => widgetGroup.PageId === Number(pageId))
                setCurrentWidgetGroup(matchingWidgetGroup)
            } else {
                const location = currentPage ? `/${currentPage}` : widgetGroups[0].Location
                const matchingWidgetGroup = widgetGroups.find(
                    (widgetGroup) => widgetGroup.Location === location
                )
                setCurrentWidgetGroup(matchingWidgetGroup)
            }
        }
    }, [location, widgetGroups, currentPage, detailKey, pageId])

    
    // update shared key (if exists) and 
    // set shared page id to null on
    // when the currentWidgetGroup changes
    useEffect(() => {
        if (currentWidgetGroup) {
            setSharedPageKey(currentWidgetGroup.SharedKey)
            setSharedPageId(null)
        }
        if(currentWidgetGroup && currentWidgetGroup.Widgets){
            setLayoutState(getLayouts(currentWidgetGroup.Widgets))
        }
    }, [currentWidgetGroup])

    useEffect(() => {
        if(currentDashboard && !currentDashboard.DashboardId){
            throw new Error("Dashboard Not Found")
        }
    }, [currentDashboard, location])


    return (
        <CurrentDashboardContext.Provider
            value={{
                dashboardName,
                currentPage,
                pageId,
                widgetId,
                currentWidgetGroup,
                detailKey,
                widgetGroups,
                currentDashboard,
                editingLayout,
                setLayoutEditingState,
                layoutState,
                setLayoutState,
                expandedWidgetId,
                setExpandedWidgetId,
                sharedPageKey,
                setSharedPageKey,
                sharedPageId,
                setSharedPageId,
                setNewObject,
                defaultDateLabel,
                pageLoading
            }}
        >
            {children}
        </CurrentDashboardContext.Provider>
    )
}

export { CurrentDashboardContext }
export default withConfig(CurrentDashboardContextProvider)
