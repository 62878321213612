import React from 'react'
import toast from '../../../elem/Toast'
import Input from '../../../elem/form/TextInput'
import { useForm } from 'react-hook-form'
import withConfig from '../../../wrappers/withConfig'

const CreateForm = ({ config, closeForm }) => {
    const { register, handleSubmit, errors } = useForm()
    const { API_URL } = config

    const POST = (newDashboard) => {
        return {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers':
                    'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
            },
            body: JSON.stringify(newDashboard),
        }
    }

    const createDashboard = (dashboard) => {
        fetch(`${API_URL}/admin/dashboard/create`, POST(dashboard))
            .then(async (response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Create Dashboard:' +
                        (e.message ? e.message : 'Dashboard create failed'),
                })
            })
            .finally(() => {
                closeForm(dashboard.DashboardName)
            })
    }

    const trimData = (data) => {
        Object.keys(data).map((key) => typeof data[key] === "string" ? data[key] = data[key].replace(/\s\s+/g, ' ').trim() : data[key])
        return data
    }


    const onSubmit = (data) => {
        createDashboard(trimData(data))
    }

    return (
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <Input
                label="Dashboard Name"
                name="DashboardName"
                register={register}
                registerParams={{
                    required: {
                        value: true,
                        message: 'This field is required.',
                    },
                    maxLength: {
                        value: 200,
                        message: 'The name may not exceed 200 characters.',
                    },
                    pattern: {
                        value: /^([a-zA-Z]+\s)*[0-9a-zA-Z\sd-]+$/,
                        message:
                            'The name may not contain special characters besides space;-',
                    },
                }}
                errors={errors}
                helper="The name of your dashboard should be something short and memorable. It will also double as the dashboard’s url. There will be more room for elaboration in the Description field."
                example="Oklahoma"
            />
            <Input
                label="Dashboard Description"
                name="DashboardDescription"
                register={register}
                registerParams={{
                    required: {
                        value: true,
                        message: 'This field is required.',
                    },
                }}
                errors={errors}
                helper="Enter a short description that tells the user about the dashboard."
                example="Oklahoma Director Dashboard"
            />
            <button className="button is-info" type="submit">
                Save
            </button>
        </form>
    )
}

export default withConfig(CreateForm)
