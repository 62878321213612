import React, { createContext, useState, useEffect, useContext } from 'react'
import GeoJSON from 'ol/format/GeoJSON'

import toast from '../../../Toast'
import { WidgetDataContext } from '../../../../wrappers/WidgetDataContext'

const FeatureContext = createContext(null)

const getFeatureFilepathAssociatedWithGroupBy = (widget, activeGroupBy) => {
    const groupByOptions = widget.GroupBy.split('|')
    const filenameOptions = widget.FeatureFiles.split('|')
    const activeIndex = groupByOptions.findIndex(
        (groupBy) => groupBy === activeGroupBy
    )
    const activeFilename = filenameOptions[activeIndex]
    return activeFilename
}

const FeatureContextProvider = ({ children }) => {
    // get the map widget data
    const { widgetData: widget, activeGroupBy } = useContext(WidgetDataContext)

    const [features, setFeatures] = useState([])

    useEffect(() => {
        // get the file name we are going to fetch from
        // the /public directory
        const featureFilename = getFeatureFilepathAssociatedWithGroupBy(
            widget,
            activeGroupBy
        )

        // fetch the file contents
        fetch(`/${featureFilename}`)
            .then(async (response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then((response) => {
                const f = new GeoJSON().readFeatures(response)
                setFeatures(f)
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message: `Map Feature File: No file found at ${featureFilename}`,
                })
            })
    }, [activeGroupBy, widget])

    return (
        <FeatureContext.Provider value={{ features }}>
            {children}
        </FeatureContext.Provider>
    )
}

export { FeatureContext }
export default FeatureContextProvider
