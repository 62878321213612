import React from 'react'
import { FaCogs } from 'react-icons/fa'
import { Link } from 'react-router-dom'

export default ({ isVisible }) => {
    if (isVisible)
        return (
            <div className="adminLink">
                <Link to={`/admin/dashboard`}>
                    <button className="button is-info">
                        <FaCogs />
                    </button>
                </Link>
            </div>
        )
    return null
}
