import React, { useEffect, useState, useContext } from 'react'
import toast from '../../../elem/Toast'
import AdminLayout from '../../../elem/admin/AdminLayout'
import { CurrentDashboardContext } from '../../../wrappers/CurrentDashboardContext'
import Widgets from '../../../elem/widgets/Widgets'
import getLayouts from '../../../../utils/widgets/getLayouts'
import { Redirect } from 'react-router-dom'
import withConfig from '../../../wrappers/withConfig'

const PageLayoutEditor = ({config}) => {
    const {API_URL} = config
    const {
        currentWidgetGroup,
        setLayoutState,
        layoutState,
        setLayoutEditingState,
    } = useContext(CurrentDashboardContext)
    const [edited, setEdited] = useState(null)
    const { Widgets: widgets } = currentWidgetGroup
    const [initialState, setInitialState] = useState(getLayouts(widgets))

    useEffect(() => {
        setLayoutEditingState(true)
        !Object.keys(layoutState).length
            ? setInitialState(getLayouts(currentWidgetGroup.Widgets))
            : setInitialState(layoutState)
    }, [currentWidgetGroup])

    if (!widgets && !Object.keys(layoutState).length) {
        return null
    }

    const PUT = (page) => {
        return {
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers':
                    'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
            },
            body: JSON.stringify(page),
        }
    }

    const updateLayout = (layouts) => {
        const pageLayout = {
            PageId: widgets[0].PageId,
            Widgets: layouts.map((layout) => ({
                Width: layout.w,
                Height: layout.h,
                X: layout.x,
                Y: layout.y,
                WidgetId: Number(layout.i.replace(/[^0-9.]+/g, '')),
            })),
        }
        
        fetch(`${API_URL}/admin/updatePageLayout`, PUT(pageLayout))
            .then(async (response) => {
                if (response.ok) {
                    return
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then(() => {
                setLayoutState(layoutState)
                setInitialState(layoutState)
                setEdited(true)
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Page Layout:' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server'),
                })
            })
    }

    if (edited) {
        return (
            <Redirect
                to={{
                    pathname: `/admin/dashboard/${currentWidgetGroup.DashboardName}/page/${currentWidgetGroup.PageId}`,
                }}
            />
        )
    }

    return (
        <div className="editDashboard">
            <AdminLayout title={'Edit Layout'}>
                <div className="container layout">
                    <Widgets />
                </div>
                <div className="buttons container">
                    <button
                        className="button is-info"
                        type="reset"
                        onClick={() => {
                            setLayoutState(initialState)
                        }}
                    >
                        Reset
                    </button>
                    <button
                        className="button is-info"
                        type="button"
                        onClick={() => {
                            updateLayout(layoutState.lg)
                        }}
                    >
                        Save
                    </button>
                </div>
            </AdminLayout>
        </div>
    )
}

export default withConfig(PageLayoutEditor)
