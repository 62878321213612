import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import toast from '../../elem/Toast'
import AdminLayout from '../../elem/admin/AdminLayout'
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import withConfig from '../../wrappers/withConfig'



const DeleteItem = ({config, location}) => {
    const {deleteProps: item} = location
    const itemType = item.itemType
    const [redirect, setRedirect] = useState(null)
    const { API_URL } = config

    const DELETE = () => {
        return {
            method: 'DELETE',
        }
    }

    const deleteItem = (id) => {
        fetch(`${API_URL}/admin/${itemType}/delete/${id}`, DELETE())
            .then(async (response) => {
                if (response.ok) {
                    return
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        `Delete ${itemType}:` +
                        (e.message ? e.message : `${itemType} delete failed`),
                })
            })
            .finally(() => {
                setRedirect(itemType)
            })
    }

    if (redirect === 'widget') {
        return (
            <Redirect
                to={{
                    pathname: `/admin/dashboard/${item.dashboardName}/page/${item.pageId}`,
                }}
            />
        )
    }
    
    if (redirect === 'page') {
        return (
            <Redirect
                to={{
                    pathname: `/admin/dashboard/${item.dashboardName}`,
                }}
            />
        )
    }

    if (redirect === 'dashboard') {
        return (
            <Redirect
                to={{
                    pathname: `/admin/dashboard`,
                }}
            />
        )
    }

    return (
        <AdminLayout title={`Delete ${itemType}`}>
            <label className="label">
                Are you sure you want to delete the {itemType}, "{item.name}"?
            </label>
            <button
                className="button is-info is-small"
                onClick={() => {
                    if (item.id >= 3) {
                        deleteItem(item.id)
                    }
                }}
            >
                <FaCheckCircle />
            </button>
            <button
                className="button is-info is-small"
                onClick={() => setRedirect(itemType)}
            >
                <FaTimesCircle />
            </button>
        </AdminLayout>
    )
}

export default withConfig(DeleteItem)
