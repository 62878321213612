import React, { useContext } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'

import { CurrentDashboardContext } from '../../wrappers/CurrentDashboardContext'
import WidgetDataContextProvider from '../../wrappers/WidgetDataContext'
import { cols, breakpoints, rowHeight } from '../../../utils/widgets/getLayouts'
import Widget from './Widget'
import { Spinner } from '../LoadingSpinner'

const ResponsiveGridLayout = WidthProvider(Responsive)

export default () => {
    const {
        currentWidgetGroup: { Widgets: widgets },
        editingLayout,
        setLayoutState,
        layoutState,
        expandedWidgetId,
        pageLoading,
    } = useContext(CurrentDashboardContext)

    const children = React.useMemo(() => {
        if (!widgets) {
            return null
        }
        return widgets.map((widget) => {
                return (
                    <div
                        className={`layoutElement ${
                            widget.WidgetId === expandedWidgetId
                                ? 'expanded'
                                : ''
                        }`}
                        key={`widget-${widget.WidgetId}`}
                    >
                        <WidgetDataContextProvider widget={widget}>
                            <Widget />
                        </WidgetDataContextProvider>
                    </div>
                )
            })
    }, [expandedWidgetId, widgets])

    if (!widgets || !layoutState || !Object.keys(layoutState).length) {
        if (pageLoading) {
            return <Spinner size="5em" />
        } else {
            return null
        }
    }

    const onLayoutChange = (layout, allLayouts) => {
        setLayoutState(allLayouts)
    }

    return (
        <div className="widgetsWrapper">
            <ResponsiveGridLayout
                className={'layoutWrapper'}
                layouts={layoutState}
                rowHeight={rowHeight}
                cols={cols}
                breakpoints={breakpoints}
                isResizable={editingLayout}
                isDraggable={editingLayout}
                onLayoutChange={onLayoutChange}
            >
                {children}
            </ResponsiveGridLayout>
        </div>
    )
}

