import React, { useRef, useMemo, useContext } from 'react'
import { useUpdate } from 'react-three-fiber'
import { Vector3, Color, Shape, Vector2, ArrowHelper, DoubleSide } from 'three'
import { SceneContext } from './Scene'
import { generateMeshFromPointCloud } from './utils'

const scale = { x: 1500, y: 1500, z: 1 / 500 }

const Mesh = ({feature, centerPoint, style}) => {
    const {
        tooltipContext: { updateTooltipState },
        tooltipEnabled,
        legendState,
    } = useContext(SceneContext)
    const { geometry, styleData: featureStyles } = feature
    const { coordinates } = geometry

    const styleObj = {
        ...featureStyles,
        ...style,
    }

    const vertices = useMemo(() => {
        return generateMeshFromPointCloud(coordinates[0], centerPoint, scale)
    }, [coordinates, centerPoint])

    const visible = legendState.find(
        (legendItem) => legendItem.layerName === feature.layerName
    ).visible

    const geometryRef = useUpdate(geometry => {
        geometry.setFromPoints(vertices)
    }, [visible])

    return (
        visible && (
            <mesh
                rotation={[-Math.PI / 2, 0, 0]}
                castShadow={false}
                name={feature.layerName}
                userData={feature.tooltipData}
                onPointerEnter={(e) => {
                    if (tooltipEnabled) {
                        const data = e.eventObject.userData
                        const event = e.nativeEvent
                        const {
                            offsetX,
                            offsetY,
                            target,
                        } = event
                        updateTooltipState({
                            data,
                            target: {
                                offsetX,
                                offsetY,
                                scrollWidth: target.scrollWidth,
                                clientHeight:
                                    target.clientHeight,
                            },
                        })
                    }
                }}
                onPointerLeave={(e) => {
                    e.eventObject.material.color = new Color(
                        styleObj.color ? styleObj.color : 'red'
                    )
                    if (visible) {
                        updateTooltipState(null)
                    }
                }}
            >
                <bufferGeometry
                    attach="geometry"
                    ref={geometryRef}
                />
                <meshBasicMaterial
                    side={DoubleSide}
                    attach="material"
                    color={styleObj.color ? styleObj.color : 'red'}
                    opacity={styleObj.opacity ? styleObj.opacity : 0.3}
                    transparent={true}
                />
            </mesh>
        )
    )
}

const Polygon = ({ feature, centerPoint, style }) => {
    const { legendState } = useContext(SceneContext)
    const geometryRef = useRef()
    const { geometry } = feature
    const { coordinates } = geometry

    const shape = useMemo(() => {
        const vectors = coordinates
            ? coordinates[0].reduce((acc, pt) => {
                  if (pt.length > 1) {
                      return [
                          ...acc,
                          new Vector2(
                              (pt[0] - centerPoint.x) * scale.x,
                              (pt[1] - centerPoint.y) * scale.y
                          ),
                      ]
                  }
                  return acc
              }, [])
            : []
        return new Shape(vectors)
    }, [coordinates, centerPoint])

    if (!(feature.data && feature.data.Top && feature.data.Bottom)) {
        return null
    }

    // calculate height as the bottom - top
    const height = - Math.abs((feature.data.Top - feature.data.Bottom)) * scale.z

    // scale top
    const top = feature.data.Top * scale.z
    
    const visible = legendState.find(
        (legendItem) => legendItem.layerName === feature.layerName
    ).visible

    return (
        visible && (
            <mesh
                rotation={[-Math.PI / 2, 0, 0]}
                castShadow={false}
                name={feature.layerName}
                position={[0,top, 0]}
            >
                <extrudeBufferGeometry
                    attach="geometry"
                    ref={geometryRef}
                    args={[shape, {steps: 1, depth: height, bevelEnabled: false}]}
                />
                <meshBasicMaterial
                    side={DoubleSide}
                    attach="material"
                    color={style.color ? style.color : 'red'}
                    opacity={style.opacity ? style.opacity : 0.3}
                    transparent={true}
                />
            </mesh>
        )
    )
}

const Line = ({ feature, centerPoint, style: layerStyle, camera }) => {
    const {
        tooltipContext: { updateTooltipState },
        tooltipEnabled,
        sharedPageKey,
        sharedPageId,
        setSharedPageId,
        legendState,
    } = useContext(SceneContext)
    const { geometry, styleData: featureStyle } = feature
    const { coordinates } = geometry

    const style = { ...layerStyle, ...featureStyle }

    const sections = useMemo(() => {
        let lastZValue = 0
        const vectors = coordinates.map((pt, idx) => {
            if (pt.length > 2) {
                return new Vector3(
                    (pt[0] - centerPoint.x) * scale.x,
                    (pt[1] - centerPoint.y) * scale.y,
                    (pt[2] - centerPoint.z) * scale.z
                )
            } else {
                // TODO: fix on sql side
                // if 2 pt wellbore, and there is no last z coordinate,
                // get the z value of the last pt
                if (idx > 0 && coordinates[idx - 1].length > 2) {
                    lastZValue = coordinates[idx - 1][2] - centerPoint.z
                }
                return new Vector3(
                    (pt[0] - centerPoint.x) * scale.x,
                    (pt[1] - centerPoint.y) * scale.y,
                    lastZValue * scale.z
                )
            }
        })
        const lineSegments = vectors.reduce((acc, curr, idx) => {
            if (idx <= vectors.length - 2) {
                const v1 = vectors[idx]
                const v2 = vectors[idx + 1]
                return [
                    ...acc,
                    {
                        v1,
                        v2,
                    },
                ]
            } else {
                return acc
            }
        }, [])

        const cylinders = lineSegments.map(({ v1, v2 }) => {
            const direction = new Vector3().subVectors(v2, v1)
            const arrow = new ArrowHelper(direction.clone().normalize(), v1)
            const rotation = arrow.rotation.clone()
            const position = new Vector3().addVectors(
                v1,
                direction.clone().multiplyScalar(0.5)
            )
            return {
                direction,
                rotation,
                position,
            }
        })
        return cylinders
    }, [coordinates, centerPoint])

    const getColor = (feature, style) => {
        const c = new Color(style.color ? style.color : 'red')
        if (sharedPageKey && sharedPageId) {
            if (sharedPageId === feature[sharedPageKey])
                return new Color('#ADD8E6')
        }
        return c
    }

    const lineWidth = style.width ? style.width * 0.02 : 0.1
    const material = (
        <meshBasicMaterial
            side={DoubleSide}
            attach="material"
            // color={style.color ? style.color : 'red'}
            color={getColor(feature, style)}
            opacity={style.opacity ? style.opacity : 0.3}
            transparent={true}
        />
    )
    const visible = legendState.find(
        (legendItem) => legendItem.layerName === feature.layerName
    ).visible

    return (
        <>
            {material && sections && visible && (
                <group rotation={[-Math.PI / 2, 0, 0]}>
                    {sections.map(
                        (
                            { direction, rotation, quaternion, position },
                            idx
                        ) => (
                            <mesh
                                key={`${feature.layerName}-line-${idx}`}
                                castShadow={true}
                                userData={feature.tooltipData}
                                onPointerEnter={(e) => {
                                    if (tooltipEnabled) {
                                        const data = e.eventObject.userData
                                        const event = e.nativeEvent
                                        const {
                                            offsetX,
                                            offsetY,
                                            target,
                                        } = event
                                        e.eventObject.material.color = new Color(
                                            'black'
                                        )
                                        updateTooltipState({
                                            data,
                                            target: {
                                                offsetX,
                                                offsetY,
                                                scrollWidth: target.scrollWidth,
                                                clientHeight:
                                                    target.clientHeight,
                                            },
                                        })
                                    }
                                }}
                                onPointerLeave={(e) => {
                                    e.eventObject.material.color = new Color(
                                        style.color ? style.color : 'red'
                                    )
                                    if (visible) {
                                        updateTooltipState(null)
                                    }
                                }}
                                onClick={() => {
                                    if (sharedPageKey) {
                                        setSharedPageId(feature[sharedPageKey])
                                    }
                                }}
                                // material={material}
                                rotation={[rotation.x, rotation.y, rotation.z]}
                                quaternion={quaternion}
                                position={position}
                                name={feature.layerName}
                            >
                                <cylinderBufferGeometry
                                    args={[
                                        lineWidth,
                                        lineWidth,
                                        direction.length(),
                                        32,
                                        1,
                                        true,
                                    ]}
                                    attach={'geometry'}
                                />
                                {material}
                            </mesh>
                        )
                    )}
                </group>
            )}
        </>
    )
}

export { Polygon, Line, Mesh }
