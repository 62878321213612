import React, { useEffect, useState, useContext, createContext, useRef } from 'react'
import { Canvas, extend, useThree, useFrame } from 'react-three-fiber'
import { Color } from 'three'
import { MapControls, OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { ConvexGeometry } from 'three/examples/jsm/geometries/ConvexGeometry'
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls'

import { getFeatures } from './utils'
import { TooltipContext } from './TooltipContext'
import { CurrentDashboardContext } from '../../../../wrappers/CurrentDashboardContext'
import { MapContext } from '../MapContext'

const SceneContext = createContext(null)

extend({
    MapControls,
    TrackballControls,
    OrbitControls,
    ConvexGeometry,
})

const Controls = ({ camera, gl }) => {
    const controlsRef = useRef(null)
    const lightRef = useRef(null)
    const { toggleTooltipEnabled, compassRef } = useContext(SceneContext)

    useFrame(() => {
        controlsRef.current.update()
        lightRef.current.position.set(...camera.position.clone().multiplyScalar(2).toArray())
        
        // apply transformation to compass based on azimuthal angle of camera
        // compassRef.current.style.transform = 'rotateX(' + controlsRef.current.getPolarAngle() + 'rad) rotateZ(' + controlsRef.current.getAzimuthalAngle() + 'rad)'
        compassRef.current.style.transform = 'rotate(' + controlsRef.current.getAzimuthalAngle() + 'rad)'
    })

    useEffect(() => {
        if (controlsRef.current) {
            controlsRef.current.addEventListener('start', () => {
                toggleTooltipEnabled(false)
            })
            controlsRef.current.addEventListener('end', () => {
                toggleTooltipEnabled(true)
            })
        }
    }, [controlsRef, toggleTooltipEnabled])

    return (
        <>
            <pointLight intensity={1} ref={lightRef} color={'white'}/>
            <orbitControls
                enableDamping={true}
                dampingFactor={0.05}
                screenSpacePanning={true}
                maxPolarAngle={Math.PI / 2}
                args={[camera, gl.domElement]}
                ref={controlsRef}
            />
        </>
    )
}

const Renderer = ({ data }) => {
    const { camera, gl } = useThree()
    const { expandedWidgetId } = useContext(SceneContext)
    const [features, setFeatures] = useState([])
    
    // set the features based on layer data
    useEffect(() => {
        const features = getFeatures(camera, gl, data)
        setFeatures(features)
    }, [camera, gl, data])

    // manually fire a resize event for when the
    // canvas size changes: see https://github.com/react-spring/react-three-fiber/issues/350
    // for tracking of this issue
    useEffect(() => {
        setTimeout(() => window.dispatchEvent(new Event('resize')), 200)
    }, [expandedWidgetId])

    return (
        <>
            <group position={[0, 5, 0]}>
                {features}
            </group>
            <Controls camera={camera} gl={gl} />
        </>
    )
}

export default ({ data, compassRef }) => {
    const tooltipContext = useContext(TooltipContext) 
    const { expandedWidgetId, sharedPageId, sharedPageKey, setSharedPageId } = useContext(CurrentDashboardContext)
    const { legendState } = useContext(MapContext)
    const [ tooltipEnabled, toggleTooltipEnabled ] = useState(true)

    return (
        <Canvas camera={{ position: [0, -11, .75] }}
            onCreated={({ gl }) => {
                gl.setClearColor(new Color('#020207'))
            }}
        >
            <fog attach="fog" args={['black', 0, 50]} />
            <SceneContext.Provider value={{
                tooltipContext, 
                expandedWidgetId, 
                sharedPageId,
                setSharedPageId,
                sharedPageKey,
                tooltipEnabled, 
                toggleTooltipEnabled, 
                compassRef, 
                legendState
            }}>
                <Renderer data={data} />
            </SceneContext.Provider>
        </Canvas>

    )
}
export { SceneContext }
