import React, { useContext } from 'react'
import Particles from 'react-particles-js'
import { DashboardContext } from '../../wrappers/DashboardContext'
import LoadingSpinner from '../../elem/LoadingSpinner'
import DashboardLink from './DashboardLink'
import particleConfig from './particleConfig'
import AdminLink from './AdminLink'

const LandingPage = () => {
    const { loading, dashboards } = useContext(DashboardContext)

    if (loading || !dashboards) {
        return <LoadingSpinner />
    }

    return (
        <>
            <Particles className={'particlesWrapper'} params={particleConfig} />
            <div className="landingPageWrapper">
                <AdminLink isVisible={false} />
                <div className="landingPage">
                    <div className="landingPageBanner">
                        <div className="landingPageBannerText is-size-1-desktop is-size-2-tablet is-size-3-mobile">
                            Director Dashboard
                        </div>
                    </div>
                    <div className="landingPageContent">
                        <div style={{zIndex: 3}} className="myDashboardsText is-size-4">
                            My Dashboards:
                        </div>
                        <div className="landingPageLinks">
                            {dashboards.map((dashboard, idx) => {
                                return <DashboardLink key={`dashboard-link-${idx}`}dashboard={dashboard} />
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LandingPage
