import React, { useState, useEffect } from 'react'
import toast from '../../../elem/Toast'
import { useForm } from 'react-hook-form'
import Select from '../../../elem/form/Select'
import Input from '../../../elem/form/TextInput'
import Checkbox from '../../../elem/form/Checkbox'
import NumberInput from '../../../elem/form/NumberInput'
import withConfig from '../../../wrappers/withConfig'

const CreatePageForm = ({ config, closeForm, dashboardId, pageIds }) => {
    const { register, handleSubmit, control, errors, unregister } = useForm()
    const {API_URL} = config
    const [id, setId] = useState(null)
    const [parentId, setParentId] = useState(null)
    const [visible, setVisible] = useState(false)
    const hiddenClass = visible ? null : 'hidden'
    const options = pageIds.map((x) => ({ label: x.name, value: x.id }))
    
    if (id) {
        closeForm(id)
    }
    
    useEffect(() => {
        if(!visible) {
            unregister(["ParentPageId", "SharedKey", "DetailKey"]) 
        }
    }, [visible, unregister])
    
    const POST = (page) => {
        return {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers':
                'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
            },
            body: JSON.stringify(page),
        }
    }
    
    const createPage = (page) => {
        
        fetch(`${API_URL}/admin/page/create`, POST(page))
        .then(async (response) => {
            if (response.ok) {
                return response.json()
            } else {
                const error = await response.text()
                throw new Error(error)
                }
            })
            .then((response) => {
                setId(response)
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                    'Create Page:' +
                    (e.message ? e.message : 'Page create failed'),
                })
            })
        }

        const handleSelectChange = (event) => {
            setParentId(event[0].value)
            return event
        }
        

        const trimData = (data) => {
            Object.keys(data).map((key) => typeof data[key] === "string" ? data[key] = data[key].replace(/\s\s+/g, ' ').trim() : data[key])
            return data
        }

    const onSubmit = (data) => {
        !visible ? delete data.ParentPageId && delete data.SharedKey && delete data.DetailKey: data.ParentPageId = data.ParentPageId.value
        createPage({ ...trimData(data), DashboardId: dashboardId, Seq: Number(data.Seq), ParentPageId: parentId })
    }

    return (
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <Input
                label="Page Name"
                name="PageName"
                register={register}
                registerParams={{
                    required: {
                        value: true,
                        message: 'This field is required.',
                    },
                    maxLength: {
                        value: 200,
                        message: 'The name may not exceed 200 characters.',
                    },
                    pattern: {
                        value: /^([a-zA-Z]+\s)*[0-9a-zA-Z\sd-]+$/,
                        message:
                            'The name may not contain special characters besides space or -',
                    },
                }}
                errors={errors}
                helper="The name of your page should be something short and memorable."
                example="Inspections."
            />
            <Input
                label="Location"
                name="Location"
                register={register}
                registerParams={{
                    required: {
                        value: true,
                        message: 'This field is required.',
                    },
                    pattern: {
                        value:  /^\/[a-z0-9\d-]*$/i,
                        message: 'This is the url of the page. Leading / is required. It should contain no special characters besides dash.'
                    }
                }}
                errors={errors}
                helper="This is the url of the page."
                example="if the location is ‘inspections’, then the page will be accessible at /dashboard_name/inspections."
            />
            <Input
                label="Icon"
                name="Icon"
                register={register}
                registerParams={{
                    required: {
                        value: true,
                        message: 'This field is required.',
                    },
                }}
                errors={errors}
                helper={`this is the name of the <a href=https://fontawesome.com/icons?d=gallery&s=solid&m=free> solid font awesome icon</a>`}
                example="address-book"
            />
            <NumberInput 
                label="Sequence"
                name="Seq"
                register={register}
                errors={errors}
                defaultValue="0"
                />
            <Checkbox
                label="Is Subpage?"
                onChange={(event) => setVisible(event.target.checked)}
            />

            <div className={hiddenClass}>
                <Select
                    label="Parent Page"
                    name="ParentPageId"
                    control={control}
                    options={options}
                    value={
                        parentId
                            ? parentId
                            : null
                    }
                    rules={ visible ? {
                        required: {
                            value: true,
                            message: 'This field is required.',
                        },
                    } : null}
                    error={errors}
                    onChange={handleSelectChange}

                />

                <Input
                    label="Shared Key"
                    name="SharedKey"
                    register={register}
                    registerParams={
                        visible
                            ? {
                                  required: {
                                      value: true,
                                      message: 'This field is required.',
                                  },
                              }
                            : null
                    }
                    errors={errors}
                    helper="TBD"
                    example="TBD"
                />
                <Input
                    label="Detail Key"
                    name="DetailKey"
                    register={register}
                    registerParams={
                        visible
                            ? {
                                  required: {
                                      value: true,
                                      message: 'This field is required.',
                                  },
                              }
                            : null
                    }
                    errors={errors}
                    helper="TBD"
                    example="TBD"
                />
            </div>
            <Checkbox
                label="Apply Date Filter"
                name="ApplyDateFilter"
                defaultChecked={true}
                register={register}
                helper="If checked, the date selection tool will apply to the widgets in the page. If unchecked, the widgets on the page are static with respect to date."
            />
            <button className="button is-info" type="submit">
                Save
            </button>
        </form>
    )
}

export default withConfig(CreatePageForm)
