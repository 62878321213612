import React, { useContext, useEffect, useState } from 'react'
import {
    PieChart,
    Pie,
    Legend,
    Cell,
    Sector,
    Tooltip,
    ResponsiveContainer,
} from 'recharts'

import { WidgetDataContext } from '../../../wrappers/WidgetDataContext'

import palette from '../../../../utils/colors/chartPalette'
import { formatNumber } from '../../../../utils/numberFormatting'

const CustomTooltip = ({ payload, data, groupKey }) => {
    const label = payload?.[0]?.payload[groupKey]
    const value = payload?.[0]?.payload['Value']
    const total = data.map((d) => d['Value']).reduce((a, b) => a + b, 0)
    const percent = formatNumber((value / total) * 100)
    return (
        <div
            className="recharts-default-tooltip"
            style={{
                padding: '10px',
                backgroundColor: 'rgb(255, 255, 255)',
                border: '1px solid rgb(204, 204, 204)',
                whiteSpace: 'nowrap',
            }}
        >
            <span style={{ color: payload?.[0]?.payload?.fill }}>
                <b>{label ? label : 'Null'}</b>:{' '}
                {formatNumber(payload?.[0]?.payload?.Value)} ({percent}%)
            </span>
        </div>
    )
}

const CustomLegend = ({
    legendPayload,
    activeIndex,
    setActiveIndex,
    hiddenState,
    updateHiddenState,
}) => {
    const hiddenIndex = legendPayload.filter(
        (item) => hiddenState[item.value] === false
    )
    const highlight = (name) => {
        const index = hiddenIndex.findIndex((item) => item.value === name)
        if (!hiddenState[name]) {
            index !== activeIndex ? setActiveIndex(index) : setActiveIndex(null)
        }
    }

    return (
        <ul className="recharts-default-legend" style={{ textAlign: 'center' }}>
            {legendPayload.map((item, index) => (
                <li
                    key={`pie-legend-${index}`}
                    className={`recharts-legend-item legend-item-${index}`}
                    style={{ display: 'inline-block', marginRight: '10px' }}
                >
                    <svg
                        className="recharts-surface"
                        width="14"
                        height="14"
                        viewBox="0 0 32 32"
                        version="1.1"
                        style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                            marginRight: '4px',
                        }}
                        onClick={() => updateHiddenState(item, index)}
                    >
                        <path
                            stroke="none"
                            fill={item.color}
                            d="M0,4h32v24h-32z"
                            className="recharts-legend-icon"
                        ></path>
                    </svg>
                    <span
                        className="recharts-legend-item-text"
                        // style={
                        //     activeIndex === index
                        //         ? {
                        //               textDecoration: 'underline',
                        //               textDecorationColor: item.color,
                        //           }
                        //         : null
                        // }
                        onClick={() => highlight(item.value)}
                    >
                        {item.value}
                    </span>
                </li>
            ))}
        </ul>
    )
}

const Chart = ({ data, showLegend }) => {
    const [chartData, setChartData] = useState([])
    const [cells, setCells] = useState(null)
    const [legendPayload, setLegendPayload] = useState([])
    const [activeIndex, setActiveIndex] = useState(null)

    // get the column name key for grouping, eg in { Value: <value>, District: <district>}
    // groupKey == 'District'
    const groupKey = Object.keys(data[0]).find((x) => !x.includes('Value'))

    // get a list of all unique groups that are present in the data
    // and sort alphabetically
    const uniqueGroups = [...new Set(data.map((x) => x[groupKey]))]
        .sort()
        .map((x) => (x === null ? (x = '') : x))

    const [hiddenState, setHiddenState] = useState(
        uniqueGroups.reduce((acc, curr) => ({ ...acc, [curr]: false }), {})
    )

    const updateHiddenState = (e, index) => {
        if (index === activeIndex) {
            setActiveIndex(null)
        }
        setHiddenState((previousState) => ({
            ...previousState,
            [e.value]: !previousState[e.value],
        }))
    }

    useEffect(() => {
        if (showLegend === 'all' && Object.values(hiddenState).includes(true)) {
            setHiddenState(
                uniqueGroups.reduce(
                    (acc, curr) => ({ ...acc, [curr]: false }),
                    {}
                )
            )
        }
        if (
            showLegend === 'none' &&
            Object.values(hiddenState).includes(false)
        ) {
            setHiddenState(
                uniqueGroups.reduce(
                    (acc, curr) => ({ ...acc, [curr]: true }),
                    {}
                )
            )
        }
    }, [showLegend])

    useEffect(() => {
        const colors = uniqueGroups.reduce((acc, curr, idx) => {
            return {
                ...acc,
                [curr]: palette[idx % palette.length],
            }
        }, {})

        const displayGroups = uniqueGroups
            .filter((groupName) => !hiddenState[groupName])
            .sort()

        setCells(() => {
            const newCells = displayGroups.map((groupName, idx) => {
                return (
                    <Cell
                        key={`cell-${groupName}-${idx}`}
                        dataKey={`${groupName}`}
                        stackId={'shared'}
                        fill={colors[groupName]}
                    />
                )
            })

            return newCells
        })

        setChartData(() => {
            const d = data
                .filter((d) => !hiddenState[d[groupKey]])
                .sort((a, b) =>
                    typeof a[groupKey] === 'string' &&
                    typeof b[groupKey] === 'string'
                        ? a[groupKey]
                              .toLowerCase()
                              .localeCompare(b[groupKey].toLowerCase())
                        : a[groupKey] - b[groupKey]
                )
            return d
        })

        setLegendPayload(() =>
            uniqueGroups.map((groupName) => ({
                color: !hiddenState[groupName]
                    ? colors[groupName]
                    : 'rgb(204, 204, 204)',
                type: 'rect',
                value: groupName,
                id: groupName,
            }))
        )
    }, [hiddenState])

    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180
        const {
            cx,
            cy,
            midAngle,
            innerRadius,
            outerRadius,
            startAngle,
            endAngle,
            fill,
            payload,
            percent,
            value,
            dataKey,
        } = props
        const sin = Math.sin(-RADIAN * midAngle)
        const cos = Math.cos(-RADIAN * midAngle)
        const mx = cx + (outerRadius + 1) * cos
        const my = cy + (outerRadius + 1) * sin
        const ex = mx + (cos >= 0 ? 1 : -1) * 5
        const ey = my - 11
        const textAnchor = cos >= 0 ? 'start' : 'end'

        return (
            <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                    {payload.name}
                </text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 2}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/> */}
                <text
                    id="textId"
                    className="is-size-7"
                    x={ex + (cos >= 0 ? 1 : -1) * 5}
                    y={ey}
                    textAnchor={textAnchor}
                    fontWeight={'bold'}
                    fill="#333"
                >{`${dataKey}: ${value}`}</text>
                <text
                    className="is-size-7"
                    x={ex + (cos >= 0 ? 1 : -1) * 12}
                    y={ey}
                    dy={14}
                    textAnchor={textAnchor}
                    fontWeight={'bolder'}
                    fill="#333"
                >
                    {`(Rate ${(percent * 100).toFixed(0)}%)`}
                </text>
            </g>
        )
    }
    return (
        <ResponsiveContainer height={'99%'}>
            <PieChart
                id="pieChart"
                margin={{
                    top: 0,
                    right: 0,
                    left: 0,
                    bottom: 0,
                }}
            >
                <Pie
                    data={chartData}
                    dataKey={`Value`}
                    nameKey={groupKey}
                    valueKey={'Value'}
                    labelLine={false}
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                >
                    {cells}
                </Pie>
                <Tooltip
                    content={
                        <CustomTooltip groupKey={groupKey} data={chartData} />
                    }
                />
                <Legend
                    content={
                        <CustomLegend
                            legendPayload={legendPayload}
                            activeIndex={activeIndex}
                            setActiveIndex={setActiveIndex}
                            hiddenState={hiddenState}
                            updateHiddenState={updateHiddenState}
                        />
                    }
                    verticalAlign="top"
                    formatter={(a, b) => {
                        return b.value ? b.value : 'Null'
                    }}
                    data={data}
                    wrapperStyle={{
                        display: showLegend ? '' : 'none',
                        top: 1,
                        left: 0,
                        overflowX: 'hidden',
                        overflowY: 'scroll',
                        alignItems: 'center',
                        width: '100%',
                    }}
                />
            </PieChart>
        </ResponsiveContainer>
    )
}

export default () => {
    const { widgetData: widget, showLegend } = useContext(WidgetDataContext)
    const data = widget ? widget.Data : null
    //added to prevent errors from groupKeys with null values

    const noNullData = data.map((x) => {
        Object.keys(x).map((y) => (x[y] === null ? (x[y] = 'Null') : x[y]))
        return x
    })
    return <Chart data={noNullData} showLegend={showLegend} />
}
