import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import CurrentDashboardContextProvider from './components/wrappers/CurrentDashboardContext'
import DateContextProvider from './components/wrappers/DateContext'
import DashboardContextProvider from './components/wrappers/DashboardContext'
import LandingPage from './components/features/landing-page/LandingPage'
import AdminDashboard from './components/features/admin/AdminDashboard'
import CreateDashboard from './components/features/admin/dashboard/CreateDashboard'
import EditDashboard from './components/features/admin/dashboard/EditDashboard'
import CreatePage from './components/features/admin/page/CreatePage'
import EditPage from './components/features/admin/page/EditPage'
import Header from './components/elem/Header'
import SideNav from './components/features/navigation/SideNav'
import TopBar from './components/elem/TopBar'
import DateSelector from './components/elem/date/DateSelector'
import DashboardPanel from './components/features/panel/DashboardPanel'
import { Toast as ToastContainer } from './components/elem/Toast'
import DeleteItem from './components/features/admin/DeleteItem'
import CreateWidget from './components/features/admin/widget/CreateWidget'
import EditWidget from './components/features/admin/widget/EditWidget'
import PageLayoutEditor from './components/features/admin/widget/PageLayoutEditor'
import ErrorBoundary from './components/features/errors/ErrorBoundary'
import PageNotFound from './components/features/errors/PageNotFound'

export default () => (
    <React.Fragment>
        <Header />
        <div className="pageContent">
            <Router>
                <ErrorBoundary>
                    <Switch>
                        <Route exact path="/admin/dashboard">
                            <DashboardContextProvider>
                                <AdminDashboard />
                            </DashboardContextProvider>
                        </Route>
                        <Route
                            path="/delete"
                            render={(props) => <DeleteItem {...props} />}
                        />
                        <Route path="/admin/dashboard/create">
                            <CreateDashboard />
                        </Route>
                        <Route
                            exact
                            path="/admin/dashboard/:dashboardName"
                            render={(props) => (
                                <CurrentDashboardContextProvider>
                                    <EditDashboard {...props} />
                                </CurrentDashboardContextProvider>
                            )}
                        />
                        <Route
                            exact
                            path="/admin/dashboard/:dashboardName/page/create"
                            render={(props) => (
                                <CurrentDashboardContextProvider>
                                    <CreatePage {...props} />
                                </CurrentDashboardContextProvider>
                            )}
                        />
                        <Route
                            exact
                            path="/admin/dashboard/:dashboardName/page/:pageId"
                            render={(props) => (
                                <CurrentDashboardContextProvider>
                                    <EditPage {...props} />
                                </CurrentDashboardContextProvider>
                            )}
                        />
                        <Route
                            exact
                            path="/admin/dashboard/:dashboardName/page/:pageId/layout"
                            render={(props) => (
                                <CurrentDashboardContextProvider>
                                    <DateContextProvider>
                                        <PageLayoutEditor {...props} />
                                        <TopBar />
                                        <DateSelector />
                                    </DateContextProvider>
                                </CurrentDashboardContextProvider>
                            )}
                        />
                        <Route
                            exact
                            path="/admin/dashboard/:dashboardName/page/:pageId/widget/create"
                            render={(props) => (
                                <CurrentDashboardContextProvider>
                                    <CreateWidget {...props} />
                                </CurrentDashboardContextProvider>
                            )}
                        />
                        <Route
                            exact
                            path="/admin/dashboard/:dashboardName/page/:pageId/widget/:widgetId"
                            render={(props) => (
                                <CurrentDashboardContextProvider>
                                    <EditWidget {...props} />
                                </CurrentDashboardContextProvider>
                            )}
                        />
                        <Route
                            exact
                            path={[
                                '/:dashboardName',
                                '/:dashboardName/:pageName',
                                '/:dashboardName/:pageName/:detailKey',
                            ]}
                        >
                            <CurrentDashboardContextProvider>
                                <DateContextProvider>
                                    <SideNav />
                                    <TopBar />
                                    <DateSelector />
                                    <DashboardPanel />
                                </DateContextProvider>
                            </CurrentDashboardContextProvider>
                        </Route>
                        <Route exact path="/">
                            <DashboardContextProvider>
                                <LandingPage />
                            </DashboardContextProvider>
                        </Route>
                        <Route component={PageNotFound} />
                    </Switch>
                </ErrorBoundary>
            </Router>
        </div>
        <ToastContainer />
    </React.Fragment>
)
