import WKT from 'ol/format/WKT'
import { Vector as VectorLayer} from 'ol/layer'
import {Vector as VectorSource} from 'ol/source'
import { getArea } from 'ol/extent'

import { featureMapStyleFunction } from './styleFunctions'

const format = new WKT()

const getMinimumLayerExtent = layers => {
    let fit = null
    layers.map(layer => {
        const layerExtent = layer.getSource().getExtent()
        if (fit) {
            // if the layerExtent is bigger than the
            // existing fit, make it the new fit target
            if (getArea(fit) < getArea(layerExtent)) {
                fit = layerExtent
            }
        } else {
            fit = layerExtent
        }
        return null
    })
    return fit
}

const getLayers = (data, options, sharedKey) => {
    // map the data received for a 2d feature map
    // in the form:
    // [{"Key": <key1>, LayerName: <layername_1>, Data: <data1>, WKT: <wkt_1>}, ...]
    // and options in the form
    // [{layerName: <layername_1>, style: <stylejson1>}, ...]
    // and transform into openmap layers that align with the data
    const { layers: styleOptions} = JSON.parse(options)

    const layerNames = [...new Set(data.map(layer => layer.LayerName))]
    const layers = layerNames.map(layerName => {
        const { style } = styleOptions.find(x => x.layerName === layerName)
        const associatedFeatures = data.filter(x => x.LayerName === layerName)
        const features = associatedFeatures.map(f => {
            // create feature from wkt
            const feature = format.readFeature(f.WKT)

            // add the sharedKey field for page interactions
            if (sharedKey) {
                feature.set(sharedKey, f[sharedKey])
            }

            // tie associated data to the wkt feature
            const featureData = JSON.parse(f.Data)
            feature.set('data', featureData)

            // tie style to feature
            Object.keys(style).map(s => {
                return feature.set(s, style[s])
            })

            return feature
        })
        const layer = new VectorLayer({
            source: new VectorSource({
                features
            }),
            style: featureMapStyleFunction(layerName),
            zIndex: style.zIndex ? style.zIndex : null
        })
        layer.set('name', layerName)
        layer.set('style', JSON.stringify(style))
        return layer
    })
    return layers
}

export { getMinimumLayerExtent }
export default getLayers