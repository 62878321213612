import React from 'react'
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

import { formatNumber } from './numberFormatting'

const transform = (node, index) => {
    if (node.name === 'a') {
        if (
            node.attribs.href.includes('http') ||
            node.attribs.href.includes('www') || node.attribs.href.includes('javascript')
        ) {
            return convertNodeToElement(node, index, transform)
        } else {
            return <Link className="internalLink" to={node.attribs.href}>{convertNodeToElement(node, index, transform)}</Link>
        }
    } else {
        return convertNodeToElement(node, index, transform)
    }
}

const formatValue = (value) => {
    if (typeof value !== 'undefined' && value !== null) {
        if (isNaN(value)) {
            if (dayjs(value).isValid()) {
                return dayjs(value).format('YYYY/MM/DD').toString()
            } else {
                return ReactHtmlParser(value, {transform})
            }
        } else {
            return formatNumber(value)
        }
    } else {
        return null
    }
}

export default formatValue
