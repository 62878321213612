import React, { useState, useEffect } from 'react'
import toast from '../../../elem/Toast'
import { useForm } from 'react-hook-form'
import { FaEdit, FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import Input from '../../../elem/form/TextInput'
import Checkbox from '../../../elem/form/Checkbox'
import Select from '../../../elem/form/Select'
import NumberInput from '../../../elem/form/NumberInput'
import withConfig from '../../../wrappers/withConfig'

const EditPageForm = ({ config, page, pages, closeForm }) => {
    const { register, handleSubmit, control, errors, unregister } = useForm()
    const [editing, setEditing] = useState(false)
    const [state, setState] = useState(page)
    const options = pages.map((x) => ({ label: x.name, value: x.id }))
    const defaultSelect = options.filter(
        (option) => option.value === state.ParentPageId
    )[0]
    const [parentId, setParentId] = useState(null)
    const [visible, setVisible] = useState(page.ParentPageId)
    const hiddenClass = visible ? null : 'hidden'
    const { API_URL } = config

    useEffect(() => {
        if (!visible) {
            unregister(['ParentPageId', 'SharedKey', 'DetailKey'])
        }
    }, [visible, unregister])

    const PUT = (page) => {
        return {
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers':
                    'Access-Control-Allow-Origin, X-Requested-With, Content-Type, Accept',
            },
            body: JSON.stringify(page),
        }
    }

    const editPage = (editedPage) => {
        fetch(
            `${API_URL}/admin/page/edit/${editedPage.PageId}`,
            PUT(editedPage)
        )
            .then(async (response) => {
                if (response.ok) {
                    return
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Edit Dashboard:' +
                        (e.message ? e.message : 'Dashboard edit failed'),
                })
            })
            .then(() => {
                setEditing(false)
                setState(editedPage)
                closeForm(editedPage)
            })
    }

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value,
        })
    }

    const handleSelectChange = (event) => {
        setParentId(event[0].value)
        return event
    }
    const handleClick = () => {
        setState(page)
        setEditing(false)
    }

    const trimData = (data) => {
        Object.keys(data).map((key) =>
            typeof data[key] === 'string'
                ? (data[key] = data[key].replace(/\s\s+/g, ' ').trim())
                : data[key]
        )
        return data
    }

    const onSubmit = (data) => {
        !visible
            ? delete data.ParentPageId && delete data.SharedKey && (data.DetailKey = "")
            : (data.ParentPageId = parentId)

        editPage({
            ...trimData(data),
            PageId: page.PageId,
            Seq: Number(data.Seq),
        })
    }

    let formClass = editing ? 'button is-info is-small' : 'hidden'
    let textClass = editing ? 'hidden' : 'button is-info is-small'

    return (
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <div className="buttons">
                <button
                    type="button"
                    onClick={() => setEditing(true)}
                    className={textClass}
                >
                    <FaEdit />
                </button>
                <button
                    type="button"
                    onClick={handleClick}
                    className={formClass}
                >
                    <FaTimesCircle />
                </button>
                <button type="submit" className={formClass}>
                    <FaCheckCircle />
                </button>
            </div>
            <div className="formContent">
                <Input
                    label="Page Name"
                    name="PageName"
                    register={register}
                    registerParams={{
                        required: {
                            value: true,
                            message: 'This field is required.',
                        },
                        maxLength: {
                            value: 200,
                            message: 'The name may not exceed 200 characters.',
                        },
                        pattern: {
                            value: /^([a-zA-Z]+\s)*[0-9a-zA-Z\sd-]+$/,
                            message:
                                'The name may not contain special characters besides space;-',
                        },
                    }}
                    errors={errors}
                    defaultValue={state.PageName}
                    onChange={handleChange}
                    active={editing}
                />
                <Input
                    label="Location"
                    name="Location"
                    register={register}
                    registerParams={{
                        required: {
                            value: true,
                            message: 'This field is required.',
                        },
                        pattern: {
                            value: /^\/[a-z0-9\d-]*$/i,
                            message:
                                'This is the url of the page. Leading / is required. It should contain no special characters besides dash.',
                        },
                    }}
                    errors={errors}
                    defaultValue={state.Location}
                    onChange={handleChange}
                    active={editing}
                />
                <Input
                    label="Icon"
                    name="Icon"
                    register={register}
                    registerParams={{
                        required: {
                            value: true,
                            message: 'This field is required.',
                        },
                    }}
                    errors={errors}
                    defaultValue={state.Icon}
                    onChange={handleChange}
                    active={editing}
                />
                <NumberInput
                    label="Sequence"
                    name="Seq"
                    register={register}
                    defaultValue={state.Seq}
                    onChange={handleChange}
                    errors={errors}
                    active={editing}
                />
                <Checkbox
                    label="Is Subpage?"
                    onChange={(event) => setVisible(event.target.checked)}
                    disabled={!editing}
                    defaultChecked={state.ParentPageId}
                />
                <div className={hiddenClass}>
                    <Select
                        name="ParentPageId"
                        label="Parent Page"
                        control={control}
                        options={options}
                        value={parentId ? parentId : null}
                        rules={
                            visible
                                ? {
                                      required: {
                                          value: true,
                                          message: 'This field is required.',
                                      },
                                  }
                                : null
                        }
                        error={errors}
                        onChange={handleSelectChange}
                        initialValue={
                            defaultSelect ? defaultSelect.value : null
                        }
                        placeholder={defaultSelect ? defaultSelect.label : null}
                        disabled={!editing}
                    />
                    <div className="help is-danger">
                        {errors.ParentPageId
                            ? errors.ParentPageId.message
                            : null}
                    </div>
                    <Input
                        label="Shared Key"
                        name="SharedKey"
                        defaultValue={state.SharedKey}
                        register={register}
                        registerParams={
                            visible
                                ? {
                                      required: {
                                          value: true,
                                          message: 'This field is required.',
                                      },
                                  }
                                : null
                        }
                        errors={errors}
                        active={editing}
                    />
                    <Input
                        label="Detail Key"
                        name="DetailKey"
                        defaultValue={state.DetailKey}
                        register={register}
                        registerParams={
                            visible
                                ? {
                                      required: {
                                          value: true,
                                          message: 'This field is required.',
                                      },
                                  }
                                : null
                        }
                        errors={errors}
                        active={editing}
                    />
                </div>
                <Checkbox
                    label="Apply Date Filter"
                    name="ApplyDateFilter"
                    defaultChecked={state.ApplyDateFilter}
                    disabled={!editing}
                    register={register}
                />
            </div>
        </form>
    )
}

export default withConfig(EditPageForm)
