import React, { useContext, useState, useEffect, useCallback, useMemo, useRef } from 'react'
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Label,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Rectangle,
} from 'recharts'
import dayjs from 'dayjs'

import { WidgetDataContext } from '../../../wrappers/WidgetDataContext'

import palette from '../../../../utils/colors/chartPalette'
import { formatNumber } from '../../../../utils/numberFormatting'
import { dateToString } from '../../../../utils/dateFormatting'
import getHiddenColumns from '../../../../utils/widgets/getHiddenColumns'

const CustomTooltip = ({ payload }) => {
    const label = payload?.[0]?.payload.Date
    const total = payload.map((d) => d['value']).reduce((a, b) => a + b, 0)

    const values = payload.map((item) => ({
        value: item.value,
        color: item.fill,
        name: item.dataKey,
        percent: formatNumber((item.value / total) * 100),
    }))
    return (
        <div
            className="recharts-default-tooltip"
            style={{
                padding: '10px',
                backgroundColor: 'rgb(255, 255, 255)',
                border: '1px solid rgb(204, 204, 204)',
                whiteSpace: 'nowrap',
            }}
        >
            <ul
                className={`recharts-tooltip-item-list ${
                    values.length > 10 ? 'is-size-7' : 'is-size-6'
                }`}
            >
                <b>{label ? dateToString(label) : 'Null'}</b>:{' '}
                {values.map((value, index) => (
                    <li
                        key={`tooltip-${index}`}
                        className="recharts-tooltip-item"
                        style={{ color: value.color }}
                    >
                        <span className="recharts-tooltip-item-name">
                            {value.name}:
                        </span>
                        <span>
                            {formatNumber(value.value)} ({value.percent}%)
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    )
}

const CustomLegend = ({
    payload,
    activeIndex,
    setActiveIndex,
    hiddenState,
    updateHiddenState,
}) => {
    const highlight = (name, color) => {
        if (!hiddenState[name]) {
            color !== activeIndex ? setActiveIndex(color) : setActiveIndex(null)
        }
    }

    const fillColor = (name) => {
        const color = !hiddenState[name]
            ? payload.filter((item) => item.dataKey === name)[0].color
            : 'rgb(204, 204, 204)'
        return color
    }

    return (
        <ul className="recharts-default-legend" style={{ textAlign: 'center' }}>
            {payload.map((item, index) => (
                    <li
                        key={`bar-legend-${index}`}
                        className={`recharts-legend-item legend-item-${index}`}
                        style={{ display: 'inline-block', marginRight: '10px' }}
                    >
                        <svg
                            className="recharts-surface"
                            width="14"
                            height="14"
                            viewBox="0 0 32 32"
                            version="1.1"
                            style={{
                                display: 'inline-block',
                                verticalAlign: 'middle',
                                marginRight: '4px',
                            }}
                            onClick={() => updateHiddenState(item, index)}
                        >
                            <path
                                stroke="none"
                                fill={fillColor(item.dataKey)}
                                d="M0,4h32v24h-32z"
                                className="recharts-legend-icon"
                            ></path>
                        </svg>
                        <span
                            className="recharts-legend-item-text"
                            style={
                                activeIndex === item.color
                                    ? {
                                          textDecoration: 'underline',
                                          textDecorationColor: item.color,
                                      }
                                    : null
                            }
                            onClick={() => highlight(item.value, item.color)}
                        >
                            {item.value}
                        </span>
                    </li>
                ))}
        </ul>
    )
}

export default () => {
    const { widgetData: widget, showLegend } = useContext(WidgetDataContext)
    const data = widget ? widget.Data : null
    const [activeIndex, setActiveIndex] = useState(null)
    const margin = {
        top: 20,
        right: 30,
        left: 10,
        bottom: 20,
    }

    const hiddenColumns = getHiddenColumns(widget)

    const sanitizedData = data
        .filter((d) => !!d.Date) // remove data w/out dates
        .map((d) => ({
            ...d,
            Date: dayjs(d.Date).toDate().getTime(),
        })) // transform dates to MM/DD/YYYY format

    // get the column name key for grouping, eg in { Date: <date>, Value: <value>, District: <district>}
    // groupKey == 'District'
    // also filter out hidden columns
    const groupKey = Object.keys(sanitizedData[0])
            .filter(x => !hiddenColumns.includes(x))
            .find(
                (x) => !(x.includes('Date') || x.includes('Value'))
            )

    // get a list of all unique groups that are present in the data
    // and sort alphabetically
    const uniqueGroups = [
        ...new Set(sanitizedData.map((x) => x[groupKey])),
    ].sort()

    // aggregate bar chart data using unique dates. eg
    // [{Date: date1, District: 1, Value: 100}, {Date: date1, District: 2, Value: 200}]
    // turns into [{Date: date1, "1": 100, "2": 200}]
    const chartData = [...new Set(sanitizedData.map((d) => d.Date))].map(
        (date) => {
            const dateEntry = { Date: date }
            return sanitizedData
                      .filter((x) => x.Date === date) // get entries associated w/ the current date
                      .reduce((acc, curr) => {
                          const groupName = curr[groupKey]
                          const value = curr['Value']
                          return {
                              ...acc,
                              [groupName]: value,
                          }
                      }, dateEntry)
        }
    )

    // create state to track hidden prop for each individual bar series
    const [hiddenState, setHiddenState] = useState(
        uniqueGroups.reduce((acc, curr) => ({ ...acc, [curr]: false }), {})
    )

    const updateHiddenState = (e) => {
        setHiddenState((previousState) => ({
            ...previousState,
            [e.dataKey]: !previousState[e.dataKey],
        }))
    }

    useEffect(() => {
        if (showLegend === 'all' && Object.values(hiddenState).includes(true)) {
            setHiddenState(
                uniqueGroups.reduce(
                    (acc, curr) => ({ ...acc, [curr]: false }),
                    {}
                )
            )
        }
        if (
            showLegend === 'none' &&
            Object.values(hiddenState).includes(false)
        ) {
            setHiddenState(
                uniqueGroups.reduce(
                    (acc, curr) => ({ ...acc, [curr]: true }),
                    {}
                )
            )
        }
    }, [showLegend])

    const containerRef = useRef({current: {container: null}})

    const [containerWidth, setContainerWidth] = useState(0)

    useEffect(() => {
        if (containerRef && containerRef.current) {
            const guessWidth = containerRef.current.container.offsetWidth - margin.left - margin.right - 40
            setContainerWidth(guessWidth)
        }
    }, [margin.left, margin.right])

    // create state to track bar width for x axis padding
    const padding = useMemo(() => {
        const minDate = dayjs(Math.min(...chartData.map(x => x.Date)))
        const maxDate = dayjs(Math.max(...chartData.map(x => x.Date)))
        const numMonths = maxDate.diff(minDate, "month") + 1
        const p = (containerWidth / numMonths ) / 2
        return {
            left: p,
            right: p
        }
    }, [chartData, containerWidth])

    // tie each groupname to a groupid, if exists. otherwise,
    // default to "shared"
    const stacks = useMemo(() => uniqueGroups.reduce((acc, groupName) => {
        const exampleRow = data.find(x => x[groupKey] === groupName)
        let stackId = 'shared' // default stack id

        // get the stack id of the group in the query, if it exists
        if (exampleRow && typeof exampleRow.GroupID !== 'undefined') {
           stackId = exampleRow.GroupID
        }
        return {
            ...acc,
            [groupName]: stackId
        }
    }, {}), [uniqueGroups, data, groupKey])
    
    const RevealBarProps = useCallback((props) => {
        return props.fill === activeIndex ? (
            <Rectangle
                {...props}
                x={props.x - 5}
                height={props.height + 1}
                width={props.width + 10}
            />
        ) : (
            <Rectangle {...props} width={props.width} />
        )
    }, [activeIndex])


    // generate a bar component for each group
    const Bars = uniqueGroups.map((groupName, idx) => (
        <Bar
            key={`bar-${idx}`}
            dataKey={`${groupName}`}
            stackId={stacks[groupName]}
            fill={palette[idx % palette.length]}
            hide={hiddenState[groupName]}
            shape={RevealBarProps}
            isAnimationActive={false}
            activeIndex={activeIndex}
        />
    ))

    return (
        <ResponsiveContainer height={'99%'} ref={containerRef} >
            <BarChart
                data={chartData}
                margin={margin}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="Date"
                    domain={[`dataMin`, `dataMax`]}
                    type="number"
                    tickFormatter={(unixTime) => dateToString(unixTime)}
                    padding={padding}
                >
                    <Label
                        value={'Date'}
                        offset={-10}
                        position="insideBottom"
                        className="barChartXAxisLabel"
                    />
                </XAxis>
                <YAxis
                    tickFormatter={(value) => formatNumber(value, 'compact')}
                >
                    <Label
                        value={widget.ValueLabel}
                        angle={-90}
                        offset={20}
                        position="insideLeft"
                        className="barChartYAxisLabel"
                    />
                </YAxis>
                <Tooltip
                    isAnimationActive={false}
                    content={
                        <CustomTooltip groupKey={groupKey} data={chartData} />
                    }
                />
                <Legend
                    content={
                        <CustomLegend
                            activeIndex={activeIndex}
                            setActiveIndex={setActiveIndex}
                            hiddenState={hiddenState}
                            updateHiddenState={updateHiddenState}
                        />
                    }
                    verticalAlign="top"
                    wrapperStyle={{
                        display: showLegend ? '' : 'none',
                        top: 1,
                        left: 0,
                        overflowX: 'hidden',
                        overflowY: 'scroll',
                        alignItems: 'center',
                        width: '100%',
                    }}
                />
                {Bars}
            </BarChart>
        </ResponsiveContainer>
    )
}
