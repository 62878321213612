import React, { createContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import withConfig from './withConfig'
import toast from '../elem/Toast'

const DashboardContext = createContext(null)

const DashboardContextProvider = ({ config, children }) => {
    const { API_URL } = config
    const [dashboards, setDashboards] = useState([])
    const location = useLocation()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true) // set the loading to true
        fetch(`${API_URL}/dashboard`)
            .then(async (response) => {
                if (response.ok) {
                    return response.json()
                } else {
                    const error = await response.text()
                    throw new Error(error)
                }
            })
            .then((response) => {
                setDashboards(response.dashboards)
            })
            .catch((e) => {
                toast({
                    level: 'error',
                    message:
                        'Dashboard Landing Page:' +
                        (e.message
                            ? e.message
                            : 'Unable to connect to the server'),
                })
            })
            .finally(() => {
                setLoading(false)
            })
    }, [API_URL, location])

    return (
        <DashboardContext.Provider value={{ loading, dashboards }}>
            {children}
        </DashboardContext.Provider>
    )
}

export { DashboardContext }
export default withConfig(DashboardContextProvider)
