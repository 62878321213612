import React, { useContext, useMemo, useCallback } from 'react'
import { useTable, useSortBy } from 'react-table'
import { WidgetDataContext } from '../../../wrappers/WidgetDataContext'
import Cell from './Cell'
import { CurrentDashboardContext } from '../../../wrappers/CurrentDashboardContext'
import { FaChevronUp, FaChevronDown } from 'react-icons/fa'
import stringFields from '../../../../utils/constants/widgets/stringFields'

const parseColumns = (data, valueLabel, sharedKey) => {
    const exampleRow = data[0] // get the first row of the data to extract cols from
    const columns = Object.keys(exampleRow)
        .filter((col) => col !== 'Value') // remove the value column, we'll add next w/ proper label
        .filter((col) => (sharedKey ? col !== sharedKey : true)) // if there is a shared key, we don't want it to display in the grid
        .map((col) => ({
            Header: () => <Cell value={col} type='string'/>,
            accessor: col,
            Cell: ({ cell }) => <Cell value={cell.value} type={stringFields.includes(col) ? 'string' : null}/>,
        })) // map column name => {Header: <column_name>, accessor: <column_name>} format

    // all values must be in the 'Value' field of the returned data
    if (Object.keys(exampleRow).find((col) => col === 'Value')) {
        const valueColumn = {
            Header: () => <Cell value={valueLabel ? valueLabel : 'Value'} type='string'/>,
            accessor: 'Value',
            Cell: ({ cell }) => <Cell value={cell.value} />,
        }
        return [...columns, valueColumn]
    } else {
        return columns
    }
}

export default () => {
    const { sharedPageKey, sharedPageId, setSharedPageId} = useContext(CurrentDashboardContext)
    const { widgetData: widget } = useContext(WidgetDataContext)
    const data = widget ? widget.Data : null
    const detailKey = widget ? widget.DetailKey : null
    const columns = useMemo(
        () => parseColumns(data, widget.ValueLabel, widget.SharedKey),
        [data, widget.ValueLabel, widget.SharedKey]
    )

    const tableData = useMemo(() => data, [data])
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({ columns, data: tableData }, useSortBy)
    
    const onClick = useCallback((row) =>
        (sharedPageKey && detailKey !== sharedPageKey) 
            && setSharedPageId(row.original[sharedPageKey]), [sharedPageKey, setSharedPageId, detailKey])

    return (
        <div className="gridWrapper">
            <table
                {...getTableProps()}
                className="table is-fullwidth is-striped"
            >
                <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps(
                                        column.getSortByToggleProps({className: 'sticky'})
                                    )}
                                >
                                    <div className="gridColumnHeader">
                                        {column.render('Header')}
                                        <span className="gridColumnIcon">
                                            {column.isSorted ? (
                                                column.isSortedDesc ? (
                                                    <FaChevronUp height='1rem' width='1rem'/>
                                                ) : (
                                                    <FaChevronDown height='1rem' width='1rem'/>
                                                )
                                            ) : (
                                                ''
                                            )}
                                        </span>
                                    </div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                        prepareRow(row)
                        return (
                            <tr
                                {...row.getRowProps()}
                                onClick={() => onClick(row)}
                                className={`${
                                    sharedPageKey &&
                                    sharedPageId === row.original[sharedPageKey]
                                        ? 'active'
                                        : ''
                                } ${sharedPageKey ? 'pointer' : ''}`}
                            >
                                {row.cells.map((cell) => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}
