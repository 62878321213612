import React, { useContext } from 'react'
import { CurrentDashboardContext } from '../wrappers/CurrentDashboardContext'
import DateBarComponent from './date/BarComponent'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import TopBarDropdown from './TopBarDropdown'
import withConfig from '../wrappers/withConfig'

export default withConfig(({config}) => {
    const {
        currentWidgetGroup: { PageName },
        dashboardName,
        pageLoading
    } = useContext(CurrentDashboardContext)
    const { IS_PUBLIC } = config
    
    return (
        <div className="topBarWrapper">
            <HelmetProvider>
                <Helmet>
                    <title>{dashboardName} {!IS_PUBLIC ? `Director ` : ''}Dashboard</title>
                </Helmet>
            </HelmetProvider>
            <div className="widgetGroupNameWrapper">
                <div className="widgetGroupName">
                    <p className="dashboardName">{dashboardName} </p>
                    {!pageLoading && <p className="pageName">- {PageName}</p>}
                    <div className="mobileName"><TopBarDropdown name={PageName} /></div>
                </div>
                <div className="topBarControls">
                    <DateBarComponent />
                </div>
            </div>
        </div>
    )
})
